$sidebarBackgroundColor: #1F1F1F;
$sidebarItemNotSelectedTextColor: #817D7C;
$sidebarItemSelectedBackgroundColor: #282828;
$sidebarItemSelectedTextColor: #FFFFFF;

$sidebarWidth: 192px;

.app-icon {
  //display: inline-block;
  //margin: 8px 0 0 20px;
  position: absolute;
  top: 25px;
  left: 20px;

  img {
    display: block;
  }
}

.sidebar {
  background-color: $sidebarBackgroundColor;
  width: $sidebarWidth;
  overflow: hidden;
  float: left;
}

.sidebar-items {
  display: block;
  margin-top: 100px;
}

.sidebar-item, .sidebar-item:hover {

  width: $sidebarWidth;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: $sidebarItemNotSelectedTextColor;
  padding-left: 20px;

  display: block;
  cursor: pointer;

  span {
    vertical-align: middle;
  }

  i {
    font-size: 20px;
  }

}
.sidebar-item.active, .sidebar-item.active:hover {
  background-color: $sidebarItemSelectedBackgroundColor;
  color: $sidebarItemSelectedTextColor;
}
