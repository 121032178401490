@import "reset";
@import "application-header";
@import "sidebar";
@import "reports";
@import "report-parameters";
@import "tt-table";
@import "pikaday";
@import "login";
@import "timers";
@import "modals";
@import "mixins";
@import "timeline";
@import "not-found";
@import "profile";
@import "admininistration";

body {
  overflow: hidden;
  font-family: $appFont;
  background: $appBgColor;

  @include unselectable-text;
}

.nested-route-wrapper {
  $nestedRouteWrapperBackgroundColour: #282828;
  display: inline-block;
  background-color: $nestedRouteWrapperBackgroundColour;

  .page-title {
    $pageTitleColor: #FFFFFF;

    font-size: 28px;
    margin-bottom: 22px;
    color: $pageTitleColor
  }
}

#test-table .ember-light-table.ember-view {
  width: 1000px;
}

.application-loading-state, .model-loading-state {

  .application-loading-spinner, .lading-spinner {
    $spinnerSize: 50px;
    $spinnerColor: #5897FA;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include spinner-animation($spinnerSize, $spinnerColor);
  }
}

.model-loading-state.hidden {
  display: none;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
