$pikadayDarkLabelColor: #6f92a1;
$pikadayDarkBackground: #1F1F1F;

.pikaday-dark {

  background-color: $pikadayDarkBackground;
  border: none;
  border-radius: 3px;

  * {
    background-color: $pikadayDarkBackground;
    color: white;
    font-size: 100%;
  }

  .pika-next{
    // We're always using a 2x image and then resizing it to 1x by setting width and height.
    // This is because there does not appear to be an easy way like srcset to do this with background-image.
    // The images are so tiny in this case that it doesn't really make a difference anyway.
    background-image: url('/assets/images/RightArrow@2x.png');
    width: 15px;
    height: 26px;
    margin: 1px 3px; // This was arrived at by trial and error - was unable to get these to align without this hack.
  }

  .pika-prev{
    // We're always using a 2x image and then resizing it to 1x by setting width and height.
    // This is because there does not appear to be an easy way like srcset to do this with background-image.
    // The images are so tiny in this case that it doesn't really make a difference anyway.
    background-image: url('/assets/images/LeftArrow@2x.png');
    width: 15px;
    height: 26px;
    margin: 1px 3px; // This was arrived at by trial and error - was unable to get these to align without this hack.
  }

  .pika-label {
    color: $pikadayDarkLabelColor;
  }

  .pika-title {
    border-bottom: 2px white;
  }

  .pika-table th {
    color: $pikadayDarkLabelColor;
  }

  .is-disabled .pika-button {
    background-color: $pikadayDarkBackground;
  }

}
