@mixin unselectable-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin  circle-animation($scaleMin, $scaleMax) {
  -webkit-animation-name: pulse;
  animation-name: pulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate-reverse;
  animation-direction: alternate-reverse;

  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale3d($scaleMin, $scaleMin, $scaleMin);
      transform: scale3d($scaleMin, $scaleMin, $scaleMin);
    }
    100% {
      -webkit-transform: scale3d($scaleMax, $scaleMax, $scaleMax);
      transform: scale3d($scaleMax, $scaleMax, $scaleMax);
    }
  }

  @keyframes pulse {
    0% {
      -webkit-transform: scale3d($scaleMin, $scaleMin, $scaleMin);
      transform: scale3d($scaleMin, $scaleMin, $scaleMin);
    }
    100% {
      -webkit-transform: scale3d($scaleMax, $scaleMax, $scaleMax);
      transform: scale3d($scaleMax, $scaleMax, $scaleMax);
    }
  }
}

@mixin spinner-animation($spinnerSize, $spinnerColor) {
  height: $spinnerSize;
  width: $spinnerSize;
  animation: rotate 1s infinite linear;
  border: 2px solid $spinnerColor;
  border-right-color: transparent;
  border-radius: 50%;

  @keyframes rotate {
    0%    {
      transform: rotate(0deg);
    }
    100%  {
      transform: rotate(360deg);
    }
  }
}
