@import "dropdowns";
@import "mixins";

$labelColor: #FFFFFF;
$inputColor: #000000;
$reportInputBackgroundColor: #ABA6A5;
$defaultInputHeight: 28px;

.report-parameters-container {
  width: 950px;
  padding-left: 10px;
  margin-bottom: 15px;


  input {
    color: $inputColor;
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    background-color: $reportInputBackgroundColor;
  }

  .report-options-group {
    color: $labelColor;
    margin-top: 20px;

    label {
      width: 100px;
      display: inline-block;
    }

    span:not(:first-child), label.report-date-range-label-to {
      margin-left: 20px;
    }

    .report-date-range-label-to {
      width: 86px;
    }

    .level.one {
      margin-left: 0;
    }

    .level.two label, .round-off-value label {
      width: 118px;
    }

    .show-days-input {

      input, label {
        cursor: pointer;
        vertical-align: middle;
      }
      label {
        width: 130px;
      }
    }

    .report-dropdown.start-of-week {
      margin-left: 12px;

      label {
        width: 105px;
      }
    }
  }

  .report-date-range {

     .report-date-range-input-from, .report-date-range-input-to {
       width: 115px;
       height: $defaultInputHeight;
     }

     // The below incantations set the placeholder text color
     // The weird :: thing seems to be a browser vendor thing
     ::-webkit-input-placeholder {
       color: $inputColor;
     }
     ::-moz-placeholder {
       color: $inputColor;
       opacity: 1;
     }
     :-ms-input-placeholder {
       color: $inputColor;
     }
   }


  .report-dropdown .ui.dropdown {
    min-width: 50px;
  }

  .granularity, .level, .time-formats, .round-off-value {
    .ui.dropdown {
      width: 100px;
      height: $defaultInputHeight;
    }
  }

  .start-of-week .ui.dropdown {
    width: 120px;
    height: $defaultInputHeight;
  }

  .rounding-type .ui.dropdown {
    width: 150px;
    height: $defaultInputHeight;
  }
}

.button-group {
  text-align: center;
}

.fetch-report-button.ui.button {
  border: none;
  border-radius: 4px;
  color: $inputColor;
  font-weight: bold;
  padding: 0;
  height: 30px;
  line-height: 30px;
  width: 230px;
  position: relative;
}

.parameters-toggle-button.ui.divider {
  text-transform: none;
  cursor: pointer;

  i {
    font-weight: bold;
  }
}
