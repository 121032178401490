@import "mixins";

$reportsBackgroundColor: #282828;
$reportsTitleTextColor: #FFFFFF;

.reports-container {
  overflow: auto;
  display: inline-block;
  background-color: $reportsBackgroundColor;
}

.reports {
  margin-left: 33px;
  margin-top: 22px;
}

// we will need a different name for this class as we may display other visuals than just text
.message-body {
  // values to be checked from sketch for consistency for font-weight, padding-top, color and font-size
  cursor: default;
  font-weight: bold;
  text-align: center;
  padding-top: 100px;
  color: #383636;
  font-size: 40px;
}

.message-body.empty-response {
  display: none;
}

.table-loading-state {
  display: none;

  .lading-spinner {
    $spinnerSize: 50px;
    $spinnerColor: #5897FA;

    position: absolute;
    top: 50%;
    right: 40%;
    transform: translate(-50%, -50%);
    @include spinner-animation($spinnerSize, $spinnerColor);
  }
}
