$applicationHeaderBackgroundColor: #1F1F1F;
$dropdownTextColor: #ABA6A5;

.application-header {
  $navHeight: 40px;

  height: $navHeight;
  background-color: $applicationHeaderBackgroundColor;

  .nav-dropdown.ui.pointing.dropdown {
    $dropdownBackground: #1F1F1F;
    $dropDownWidth: 200px;


    float: right;
    margin-right: 10px;
    padding: 8px 0;
    height: $navHeight;
    width: $dropDownWidth;
    cursor: pointer;


    .dropdown-trigger {
      float: right;
      cursor: pointer;

      i {
        color: $dropdownTextColor;
        vertical-align: super;
        margin: 0;
      }
    }

    .nav-dropdown-menu.menu {
      $separatorColor: #ABA6A5;
      $itemHoverBgColor: #6F779E;
      $shadowColor: rgba(0, 0, 0, 0.5);

      width: $dropDownWidth;
      background: $dropdownBackground;
      color: $dropdownTextColor;
      box-shadow: 0 2px 5px 0 $shadowColor;

      .user-info {
        padding:10px;

        div:first-child {
          margin-bottom: 10px;
        }
      }

      .nav-separator-line {
        height: 1px;
        margin: 3px 1px;
        background-color: $separatorColor;
      }

      .links {
        margin: 10px 0 0 0;
      }

      .item {
        cursor: pointer;
        padding: 5px 10px !important;
        margin: 3px 0;
        display: block;
        color: $dropdownTextColor;
        font-weight: normal;
      }

      .item:hover {
        $hoveredTextColor: #FFFFFF;

        background:  $itemHoverBgColor;
        color: $hoveredTextColor;
      }

      a div {
        cursor: pointer;
      }
    }

    .menu:after {
      left: 90%;
      margin-left: 5px;
      background-color: $dropdownBackground;
    }
  }
}


