@import "mixins";
@import "constants";

$buttonSpacing: 30px;

$projectsSidebarBackground: #1F1F1F;
$projectsListItemBackground: #282828;
$projectListItemHoverBackground: #3C3838;

$coloredRectangleHeight: 30px;
$coloredRectangleWidth: 40px;
$projectsListTextBoxWidth: 135px;


#sidebar-toggle-button {

  $toggleButtonHeight: 30px;
  $toggleButtonTextColor: #D2D2D2;
  $toggleButtonBgColor: #1F1F1F;
  $toggleButtonFontSize: 20px;

  height: $toggleButtonHeight;
  background: $toggleButtonBgColor;
  color: $toggleButtonTextColor;
  padding: 3px 10px;
  border-radius: 0;
  display: none;
  font-size: $toggleButtonFontSize;
  font-weight: normal;
  cursor: pointer;
  font-family: $appFont;
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

#sidebar-toggle-button.moving-button-in {
  transform: translate3d(-260px, 0, 0) rotate(270deg);
}

#sidebar-toggle-button.moving-button-out {
  transform: translate3d(0%, 0%, 0) rotate(270deg);
}

#projects-sidebar {

  background-color: $projectsSidebarBackground;

  .projects-search {
    $iconColor: #7F7F7F;
    $borderColor: #817D7C;

    width: 240px;
    margin: 10px 10px 30px 10px;
    border: 1px solid $borderColor;
    border-radius: 4px;
    color: $iconColor;

    input {
      $searchBgColor: #282828;
      $searchTextColor: #FFFFFF;

      background: $searchBgColor;
      color: $searchTextColor;
    }
  }

  .projects-list-item {

    $projectListFontSize: 18px;

    position: relative;
    font-size: $projectListFontSize;
    margin: 0 10px $buttonSpacing 10px;
    padding: 20px 0 20px 10px;
    background-color: $projectsListItemBackground;
    border-bottom: solid rgba(0, 0, 0, 0.3) 3.5px;
    border-radius: 8px;

    .projects-sidebar-list-color-box {
      width: $coloredRectangleWidth;
      height: $coloredRectangleHeight;
      border-radius: 5px;
      display: inline-block;
      cursor: pointer;
    }

    .project-sidebar-list-text {
      width: $projectsListTextBoxWidth;
      margin-left: 10px;
      color: white;
      font-weight: 400;
      // position element vertically centered
      // it was painful to center align all three items in the parent route
      // sometimes the text would disappear for the projects
      // that needs to be scrolled up
      // I'll keep looking for better solution
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
  }

  .projects-list-item:hover {
    background-color: $projectListItemHoverBackground;
    cursor: pointer;
  }

  .empty-projects-message {
    display: block;
    text-align: center;
  }

  div.empty-projects-message {
    color: #383636;
    font-weight: bold;
    font-size: 20px;
    margin-top: 50px;
    padding: 0 10px;
  }
  a.empty-projects-message {
    margin-top: 10px;
    cursor: pointer;
  }

}

#projects-sidebar > .projects-list-item:first-child {
  margin-top: $buttonSpacing;
}
