@import "mixins";

$timerFontSize: 20px;
$timerContainerWidth: 200px;
$timerContainerHeight: 150px;

$timerWidth: 70px;
$timerHeight: 40px;

$runningTimerHeight: 25px;
$runningTimerWidth: 45px;
$runningTimerFontSize: 14px;

.timers-screen-total-time {
  $textColor: #FFFFFF;

  text-align: center;
  font-size: 24px;
  color: $textColor;
  margin-bottom: 30px;
}

.recent-timer-container {
  $timerContainerMarginRight: 50px;
  $timerContainerMarginBottom: 85px;

  width: $timerContainerWidth;
  height: $timerContainerHeight;
  background-color: #1f1f1f;
  display: inline-block;
  text-align: center;
  font-size: $timerFontSize;
  border-radius: 8px;
  margin: 0 $timerContainerMarginRight $timerContainerMarginBottom 0;
  padding: 0 5px;
}

.recent-timer {
  $timerMarginTop: 35px;
  $timerMarginBottom: 25px;

  width: $timerWidth;
  height: $timerHeight;
  line-height: $timerHeight;
  color: white;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  font-size: $timerFontSize;
  margin: $timerMarginTop auto $timerMarginBottom auto;
}

.running-timer {
  width: $runningTimerWidth;
  height: $runningTimerHeight;
  line-height: $runningTimerHeight;
  color: white;
  border-radius: 4px;
  text-align: center;
  font-size: $runningTimerFontSize;
  margin: 10px;
  float: right;
}

.active-timer{
  $circleDiameter: 55px;

  width: $circleDiameter;
  height: $circleDiameter;
  line-height: $circleDiameter;
  border-radius: 50%;
  margin-top: 25px;
  margin-bottom: 20px;
  @include circle-animation(1, 1.2);
}

.empty-recent-timers-message {

  display: block;
  color: #383636;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  padding: 50px 0
}
