@import "constants";

$borderColor: #817D7C;
$textColor: #FFFFFF;

.admin-page-container {
  $buttonBorderColor: $borderColor;
  $inputBorderColor: $borderColor;
  $buttonBgColor: #282828;
  $inputBgColor: $buttonBgColor;
  $placeholderColor: #A7A7A2;
  $messageTextColor: #383636;



  height: inherit;
  width: inherit;
  overflow-y: auto;
  padding: 25px 0 0 33px;

  .admin-page-section {
    $sectionContentHeight: 550px;
    $sectionWidth: 900px;
    $sectionBgColor: #1F1F1F;
    $errorMessageColor: #FF2521;


    width: $sectionWidth;
    color: $textColor;
    margin-bottom: 50px;


    .section-header {
      font-size: 24px;
    }

    .section-content {
      border-radius: 8px;
      box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.3);
    }

    .project-administration-container, .user-administration-container {
      height: $sectionContentHeight;
      margin-top: 30px;
      background-color: $sectionBgColor;

      .project-list-container, .users-in-project-list-container, .user-list-container {
        $istContainerWidth: 300px;

        width: $istContainerWidth;
        padding: 10px;
        overflow: hidden;
        display: inline-block;

        .list-header {
          $headerHeight: 32px;

          margin-bottom: 20px;
          height: $headerHeight;

          .projects-search, .users-search {
            $iconColor: #7F7F7F;
            $searchDivWidth: 210px;
            $searchBgColor: #282828;
            $inputHeight: 32px;

            width: $searchDivWidth;
            border: 1px solid $inputBorderColor;
            border-radius: 5px;
            color: $iconColor;
            height: $inputHeight;
            vertical-align: top;

            input {
              background: $searchBgColor;
              color: $textColor;
              // vendor CSS is forcing a padding of 10px on the right, causing bug in IE
              // forcing the padding with important fixes it
              padding: 0 0 0 10px !important;
              font-family: $appFont;
            }
          }

          .add-button {
            $listButtonBgColor: #282828;
            $addButtonWidth: 50px;
            $addButtonHeight: 32px;

            height: $addButtonHeight;
            width: $addButtonWidth;
            margin-left: 10px;
            border: 1px solid $buttonBorderColor;
            border-radius: 5px;
            color: $textColor;
            background-color: $listButtonBgColor;
          }

          .all-users {
            display: inline-block;
            line-height: 30px;
            margin: 0 5px 0 10px;
            max-width: 50px;
            transition: max-width 300ms ease;

            label {
              color: $textColor;
            }
          }
          .all-users.animating.out, .all-users.hidden {
            max-width: 0;
          }
        }

        .project-list-content, .users-in-project-list-content, .user-list-content {
          $listWidth: 290px;

          width: $listWidth;
          overflow-y: auto;
          overflow-x: hidden;

          .list-item {
            $listItemBorderColor: $borderColor;

            padding: 10px;
            cursor: pointer;
            border-bottom: 1px solid $listItemBorderColor;
          }

          .project-list-message, .user-list-message {
            display: block;
            color: $messageTextColor;
            font-weight: bold;
            font-size: 22px;
            text-align: center;
            margin-top: 100px;
          }
        }
      }

      .project-list-container, .user-list-container {
        $nameListContentHeight: 470px;

        height: $sectionContentHeight;
        border-right: 1px solid $borderColor;

        .project-list-content, .user-list-content {
          $projectListHoverColor: #3C3838;

          height: $nameListContentHeight;

          .list-item:hover {
            background-color: $projectListHoverColor;
          }

          .list-item.archived, .list-item.disabled-user {
            text-decoration: line-through;

            span {
              opacity: 0.5;
            }
          }
        }
      }

      .project-details, .user-details {
        $projectDetailsContainerWidth: 570px;

        width: $projectDetailsContainerWidth;
        margin-left: 20px;
        height: $sectionContentHeight;
        display: inline-block;
        overflow: hidden;
        vertical-align: top; //this fixes safari bug where the details layout is broken

        button {
          $buttonHeight: 30px;

          height: $buttonHeight;
          border: 1px solid $buttonBorderColor;
          border-radius: 5px;
          color: $textColor;
          background-color: $buttonBgColor;
        }

        .project-details-header, .user-details-header {
          $headerButtonsWidth: 90px;

          margin: 10px 0 20px 20px;
          height: 30px;

          button {
            min-width: $headerButtonsWidth;
          }

          .right-button-group {
            float: right;
            margin-right: 10px;

            .project-edit-button, .project-save-button, .user-save-button, .user-edit-button {
              margin-right: 10px;
            }
          }
        }

        .project-details-body, .user-details-body {

          height: 490px;
          overflow-y: auto;

          .section {
            $sectionLabelWidth: 100px;
            $labelContainerHeight: 30px;

            margin-bottom: 20px;
            min-height: 35px;

            div.label {
              width: $sectionLabelWidth;
              display: inline-block;
              line-height: $labelContainerHeight;
            }

            > input:disabled {
              $disabledStateWidth: 450px;

              width: $disabledStateWidth;
              background-color: transparent;
              border-color: transparent;
              user-select: none;
              padding-left: 0;
            }

            > input {
              $inputHeight: 30px;
              $inputWidth: 240px;


              background-color: $inputBgColor;
              border: 1px solid $inputBorderColor;
              border-radius: 5px;
              line-height: $inputHeight;
              height: $inputHeight;
              width: $inputWidth;
              padding-left: 10px;
            }

            input::placeholder {
              color: $placeholderColor;
            }

            .generate-password {
              cursor: pointer;
              color: #5897FA;
              padding-left: 10px;
            }

            .generate-password:hover {
              text-decoration: underline;
            }

            .permission label {
              min-width: 230px;
              display: inline-block;
            }

            .checkbox input[disabled] {
              cursor: default;
            }

          }

          .section:last-of-type {
            margin-bottom: 0;
          }

          .section:nth-child(2) .label, .section:nth-child(3) .label {
            vertical-align: top;
          }

          .color-rectangle {
            $rectangleDivWidth: 70px;
            $rectangleDivHeight: 30px;

            width: $rectangleDivWidth;
            height: $rectangleDivHeight;
            border-radius: 5px;
            display: inline-block;
          }

          .editable-color {
            border: 1px solid $buttonBorderColor;
            cursor: pointer;
          }

        }

        .project-details-message, .user-details-message {
          display: block;
          color: $messageTextColor;
          font-weight: bold;
          font-size: 30px;
          text-align: center;
          margin-top: 225px;
        }

        .users-in-project-list-container {
          $userListContainerHeight: 375px;
          $userListContentHeight: 300px;


          margin: 0;
          height: $userListContainerHeight;
          box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.3);
          border-radius: 8px;

          .users-in-project-list-content {
            height: $userListContentHeight;

            .user-list-message {
              margin-top: 50px;
            }

            .list-item {
              cursor: default;
              padding-right: 0;

              div {
                $listItemNameWidth: 270px;

                width: $listItemNameWidth;
                display: inline-block;

                label {
                  color: $textColor;
                }
              }

              .icon {
                float: right;
                cursor: pointer;
                margin: 0 5px 0 0;
              }
            }
          }
        }
      }

      $errorMessageDivWidth: 300px;


      .ui.message {
        margin: 0;
        padding: 0;
        box-shadow: none;
        display: inline-block;
        background: transparent;

        p {
          opacity: 1;
          font-size: 18px;
        }
      }

      .ui.message.negative {
        width: $errorMessageDivWidth;
        text-align: center;
        color: $errorMessageColor;
      }

      .ui.message.negative:not(.empty-user-list) {
        line-height: 35px;
        margin-left: 30px;
      }

      .ui.message.checkbox-information {
        color: $textColor;
        border: 1px solid $borderColor;
        margin: 10px 0 0 0;
        padding: 5px;
      }
    }

    button {
      cursor: pointer;
    }
  }
}


.project-color-selection.dropdown .menu {
  $menuBgColor: #282828;
  $menuWidth: 300px;

  background-color: $menuBgColor;
  min-width: $menuWidth;
  box-shadow: 0 3px 6px 3px rgba(0, 0, 0, 0.5);
  border-radius: 8px;

  .color-rectangle.item {
    margin: 15px 13px;
    border: 1px solid $borderColor;
  }
}

.project-color-selection.ui.disabled {
  opacity: 1;
}

.section .ui.checkbox input ~ label, .section input {
  color: $textColor;
}

.admin-access-denied-error .content .message {
  text-align: center;
}
