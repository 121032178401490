@import "mixins";

.profile-container {
  height: inherit;
  width: inherit;
  overflow-y: auto;
  padding: 25px 0 0 33px;
  font-size: 18px;
}

.profile-edit-container {
  $errorIndicatorColor: #FF2521;
  $labelMargin: 205px;
  $textColor: #ABA6A5;
  $buttonHeight: 30px;

  button {
    $buttonBgColor: #ABA6A5;
    $buttonTextColor: #000000;
    $buttonWidth: 200px;

    display: block;
    border: none;
    border-radius: 4px;
    color: $buttonTextColor;
    background-color: $buttonBgColor;
    font-weight: bold;
    height: $buttonHeight;
    width: $buttonWidth;
  }

  button:hover:enabled {
    background-color: #CCCCCC;
  }

  button:disabled {
    background-color: rgba(171, 166, 165, 0.51);
  }

  .profile-picture-container {
    margin: 0 0 30px 260px;

    display: none;  // profile change is not implemented yet

    .profile-picture {
      width: 200px;
      height: 200px;
    }

    .change-picture-button {
      margin-top: 10px;
    }

  }

  .edit-section {

    color: $textColor;
    margin-bottom: 30px;

    label {
      $labelWidth: 200px;

      width: $labelWidth;
      display: inline-block;
      font-weight: bold;
    }

    input {
      $inputBgColor: #ABA6A5;
      $inputTextColor: #000000;
      $inputHeight: 30px;
      $inputWidth: 300px;

      background-color: $inputBgColor;
      color: $inputTextColor;
      height: $inputHeight;
      width: $inputWidth;
      padding-left: 10px;
      border: none;
      border-radius: 4px;
    }

    .input-error-state {
      box-shadow: 0 0 0 1px $errorIndicatorColor inset;
    }

    .update-name-button, .update-password-button {
      display: inline-block;
      margin: 10px 0 20px $labelMargin;

      .button-spinner {
        $spinnerHeight: 20px;
        $spinnerColor: #1F1F1F;

        display: none;
        margin: auto;
        @include spinner-animation($spinnerHeight, $spinnerColor);
      }
    }



    div {
      margin: 15px 0;
    }
  }

  .ui.message {
    $messageContainerWidth: 350px;

    width: $messageContainerWidth;
    padding: 0 0 0 10px;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    line-height: 18px;
    display: inline-block;

    p {
      margin: 0;
      opacity: 1;
    }
  }

  .ui.message.change-password-success, .ui.message.change-name-success {
    color: $textColor;
    margin-left: 10px;
  }

  .ui.message.negative {
    color: $errorIndicatorColor;
  }
}
