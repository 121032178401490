@import "recent-timers";
@import "projects-sidebar";

$timerScreenBackgroundColor: #282828;
$newTimerListBackgroundColor: #1F1F1F;

$timerHeadTextColor: #FFFFFF;
$newTimerListItemTextColor: #FFFFFF;

$newTimerListWidth: 192px;
$newTimerListItemHeight: 45px;

$newTimerListMarginRight: 25px;

$timerScreenMarginLeft: 33px;
$timerScreenMarginTop: 25px;

.timer-screen-wrapper {

  overflow: hidden;
  display: inline-block;
  background-color: $timerScreenBackgroundColor;

  .timer-screen {
    padding: $timerScreenMarginTop 0 0 $timerScreenMarginLeft;

  }

  .timers-container {
    float: left;
    width: 100%;

    .recent-timers-wrapper {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  .start-new-timer-list {
    display: table;
    float: right;
    width: $newTimerListWidth;
    background-color: $newTimerListBackgroundColor;
    margin-right: $newTimerListMarginRight;

    .new-timer-list-heading {
      $listHeadingPaddingBottom: 5px;
      $listHeadingFontSize: 20px;
      $listHeadingTextColor: #929292;

      color: $listHeadingTextColor;
      font-size: $listHeadingFontSize;
      height: $newTimerListItemHeight - $listHeadingPaddingBottom;
      text-align: center;
      vertical-align: bottom;
      display: table-cell;
      width: $newTimerListWidth;
      padding-bottom: $listHeadingPaddingBottom;
    }

    hr {
      $separatorColor: #929292;

      background-color: $separatorColor;
      border: none;
      margin: 0;
      height: 2px;
    }

    .new-timer-list-heading + hr {
      $listHeaderSeparatorMargin: 10px;

      // apply margin of 10 on both sides and reduced width by 20
      width: $newTimerListWidth - 2 * $listHeaderSeparatorMargin;
      margin-left: $listHeaderSeparatorMargin;
    }

    .project-list-item {
      $listItemFontSize: 18px;
      $listItemPadding: 8px;

      display: table-cell;
      width: $newTimerListWidth;
      color: $newTimerListItemTextColor;
      height: $newTimerListItemHeight;
      font-size: $listItemFontSize;
      vertical-align: middle;
      padding-left: $listItemPadding;
      cursor: pointer;
    }
  }
}
