@import "constants";
@import "time-picker";

$timelineTextColor: #FFFFFF;

.full-calendar {
  $calendarBorderColor: rgba(96, 92, 100, 0.5);

  color: $timelineTextColor;
  border-color: $calendarBorderColor;

  th, td {
    border-color: $calendarBorderColor;
  }

  td.fc-today {
    $todayBGColor: #343434;

    background-color: $todayBGColor;
  }

  .fc-time-grid {
    $fcGridRowHeight: 50px;

    .fc-slats td {
      height: $fcGridRowHeight;
    }

    .fc-event-container .fc-time-grid-event {

      .fc-content {
        $fcEventTitleFontSize: 14px;

        div {
          display: block;
          font-size: $fcEventTitleFontSize;
          font-weight: bold;
          margin-left: 5px;
        }

        .fc-title {
          padding-top: 3px;
        }
       }
      .fc-bg {
        background: none;
      }
    }
  }
  .fc-disabled-day {
    background: none;
  }

  .fc-toolbar.fc-header-toolbar {
    padding: 10px 0;
    margin: 0;

    .fc-left {
      padding-left: 10px;
    }
    .fc-right {
      padding-right: 10px;
    }

    $buttonBorderColor: #605c64;
    .fc-button {
      background-color: #1F1F1F;
      color: $timelineTextColor;
      border: 1px solid $buttonBorderColor;
      background-image: none;
      box-shadow: none;
      text-shadow: none;
    }
    .go-to-date-picker {
      height: 34px;
      width: 100px;
      border-radius: 4px;
      color: $timelineTextColor;
      border: 1px solid $buttonBorderColor;
      text-align: center;
      cursor: pointer;
    }
    ::-webkit-input-placeholder {
      color: $timelineTextColor;
    }
    ::-moz-placeholder {
      color: $timelineTextColor;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: $timelineTextColor;
    }
    .add-button {
      border-radius: 4px;
    }

    .fc-icon-right-single-arrow:after, .fc-icon-left-single-arrow:after {
      top: 6px;
    }

    .fc-button.fc-state-active, .fc-button.fc-state-disabled {
      opacity: 0.5;
      cursor: default;
    }

    .fc-button .fc-icon {
      height: 34px;
      font-family: $appFont;
    }

    // alignment is messed up in firefox after fixing it elsewhere
    @-moz-document url-prefix() {
      .fc-icon-right-single-arrow:after, .fc-icon-left-single-arrow:after {
        top: 5px;
      }
    }

    .fc-center {
      line-height: 30px; // align vertically
    }
  }
  .fc-agendaDay-view, .fc-agendaWeek-view {
    $fcAgendaDayGridTopBarHeight: 30px;

    .fc-row .fc-widget-header {

      height: $fcAgendaDayGridTopBarHeight;
      vertical-align: middle;

      span:first-child {
        float: left;
        margin-left: 10px;
      }
      span:nth-child(2) {
        float: right;
        margin-right: 10px;
      }
    }
    .fc-row {
      border-color: $calendarBorderColor;
    }
  }

  .fc-agendaDay-view {
    .fc-event-container {
      margin: 0 10px 0 10px;
    }
  }

  .fc-agendaWeek-view {
    .fc-event-container {
      margin: 0 3px 0 3px;

      .fc-time-grid-event .fc-content {
        .fc-title {
          margin: 0 3px;
          display: block;
          padding-left: 0;
        }
        .fc-time {
          margin: 0 3px;
          display: block;
          padding-left: 0;
        }
      }
    }
  }

  .fc-month-view {
    $popoverColor: #1F1F1F;
    $popoverBorderColor: $calendarBorderColor;

    .fc-popover {
      background-color: $popoverColor;
      border-color: $popoverBorderColor;

      .fc-header {
        background-color: $popoverColor;
        padding-top: 5px;
      }
    }
  }

  $popupBackground: #343434;

  .ui.popup {
    $popupWidth: 300px;
    $popupHeight: 200px;
    $popupTextColor: $timelineTextColor;

    width: $popupWidth;
    max-width: $popupWidth;
    max-height: $popupHeight;
    padding: 10px;
    font-family: $appFont;
    background-color: $popupBackground;
    color: $popupTextColor;

    .popup-close-icon {
      float: right;
      cursor: pointer;
    }

    .popup-project-name{
      font-weight: bold;
      font-size: 18px;
    }

    button {
      font-family: $appFont;
      margin-top: 10px;
    }

    .archive-button {
      padding: 0 10px;
    }

    .edit-button {
      padding: 0 20px;
      float: right;
    }
  }
  .ui.popup:before, .ui.right.center.popup:before, .ui.left.center.popup:before, .ui.bottom.center.popup:before {
    background-color: $popupBackground;
  }
}

.timeline-popup {
  display: none;
}

.timeline-popup-copy {
  .popup-start, .popup-end {
    margin-bottom: 10px;
  }
  .popup-start span, .popup-end span, .popup-duration span {
    font-weight: bold;
    padding-right: 10px;
  }

  .popup-end span {
    padding-right: 15px;
  }
}



.timeline-date-picker, .time-picker-input {
  $dateTimeInputBGColor: #ABA6A5;
  $dateTimeInputTextColor: #000000;
  $inputWidth: 110px;
  $inputHeight: 25px;

  background-color: $dateTimeInputBGColor;
  color: $dateTimeInputTextColor;
  border: none;
  border-radius: 4px;
  height: $inputHeight;
  width: $inputWidth;
  padding-left: 8px;
}

.month-navigation {
  // The months selection dropdown is to be placed in top of month name in the header of
  // timeline in month view.
  position: absolute;
  top: 50px;
  // left property is set using javascript in components/timeline-view-component
  display: none;

  .pika-label {
    background: transparent;
    color: transparent;
  }

  select {
    $monthDropdownBackground: #1F1F1F;

    background-color: $monthDropdownBackground;
    color: $timelineTextColor;
  }
}
