@import "mixins";

.container-not-found, .container-unauthorized {
  $containerHeight: 500px;
  $containerBackground: #1F1F1F;
  $timeColor: #282828;
  $circleBackground: #42A5F5;
  $linkColor: #42A5F5;

  width: 100%;
  height: $containerHeight;
  background: $containerBackground;
  color: white;
  text-align: center;
  padding-top: 50px;

  .timer-circle {
    $circleDiameter: 240px;

    background: $circleBackground;
    border-radius: 50%;
    height: $circleDiameter;
    line-height: $circleDiameter;
    width: $circleDiameter;
    vertical-align: middle;
    text-align: center;
    margin: 0 auto 70px auto;
    box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, .3);

    span {
      font-size: 100px;
      color: $timeColor;
      font-weight: bold;
      text-shadow: 1px 4px 3px rgba(0, 0, 0, 0.3);
    }

    @include circle-animation(1, 1.1);

  }

  .not-found-message, .unauthorized-message {
    font-size: 35px;

    .not-found-home-link {
      color: $linkColor;
    }
  }
}
