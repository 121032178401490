@import "mixins";
@import "constants";

$modalBackgroundColor: #343434;
$modalTextColor: #FFFFFF;

.request-error-modal.ui.modal, .timeline-confirmation-modal.ui.modal,
.timeline-create-timer.ui.modal, .admin-access-denied-error.ui.modal,
.admin-page-custom-error.ui.modal, .email-not-verified.ui.modal,
.change-password-modal.ui.modal, .forgot-password-modal.ui.modal {

  $modalBackgroundColor: #343434;

  position: absolute;
  font-family: $appFont;
  background-color: $modalBackgroundColor;

  @include unselectable-text;


  .content {
    $modalContentFontSize: 18px;
    $modalContentLineHeight: 30px;

    background-color: $modalBackgroundColor;
    color: $modalTextColor;
    font-size: $modalContentFontSize;
    margin: 0;
    line-height: $modalContentLineHeight;

    span {
      display: block;
    }
  }

  .actions {
    text-align: center;
    background-color: $modalBackgroundColor;
    margin: 0;
    border: none;

    .ui.approve.button, .ui.deny.button {
      font-family: $appFont;
      margin: 0;
    }
  }
}

.timeline-confirmation-modal.ui.modal {
  $modalWidth: 400px;

  text-align: center;
  width: $modalWidth;

  .content {
    padding: 0;
  }

  .actions {
    $modalActionsSectionHeight: 60px;
    $modalActionsSectionAdjacentPadding: 37px;

    height: $modalActionsSectionHeight;
    padding: 0 $modalActionsSectionAdjacentPadding;

    .ui.approve.button {
      float: left;
    }

    .ui.deny.button {
      float: right;
    }
  }
}

.request-error-modal.ui.modal, .admin-page-custom-error.ui.modal {
  $modalWidth: 600px;

  text-align: center;
  width: $modalWidth;

  .actions {
    $modalActionsSectionBottomPadding: 10px;

    padding: 0 0 $modalActionsSectionBottomPadding 0;
    height: 70px;

    .ui.approve.button, .ui.deny.button {
      $modalButtonColor: #1F1F1F;

      background-color: $modalButtonColor;
      color: $modalTextColor;
      box-shadow: 1px 1px 1px 1px $modalButtonColor;
    }
  }
}

.timeline-create-timer.ui.modal {
  $modalWidth: 460px;

  width: $modalWidth;

  .content {

    padding-right: 0;
    padding-bottom: 0;

    span {
      display: inline;
    }
    > div label {
      display: inline-block;
      width: 130px;
    }

    .modal-start-time, .modal-end-time {
      margin-top: 20px;

      > i {
        opacity: 0.5;
        cursor: pointer;
      }

      > i:hover {
        opacity: 1;
      }
    }
  }

  .error-message {
    height: 45px;
    color: red;
    padding: 10px 20px 5px 20px;
    text-align: center;
  }

  .actions {
    $modalActionsSectionHeight: 60px;

    height: $modalActionsSectionHeight;
    padding-top: 0;

    .ui.approve.button {
      float: right;
    }

    .ui.deny.button {
      float: left;
    }
  }

}

.ui.modal.conflict-resolution-modal {
  background-color: $modalBackgroundColor;
  color: $modalTextColor;
  max-width: 750px;

  .header {
    background-color: $modalBackgroundColor;
    color: $modalTextColor;
    border-color: $modalTextColor;
    text-align: center;
    font-size: 20px;
    padding: 10px;
  }

  .conflicting-data {
    margin: 10px;

    .server-data, .local-data {
      display: inline-block;
      margin-left: 10px;
      font-size: 18px;

      h5 {
        text-align: center;
        margin-bottom: 10px;
        font-weight: bold;
      }

      div.row {
        line-height: 20px;

        span.key {
          display: inline-block;
          min-width: 95px;
        }

        span.content {
          margin-left: 5px;
        }
      }

      .project-name span.content {
        max-width: 230px;
        display: inline-block;
      }

      .button-wrapper {
        text-align: center;
      }

      .ui.button {
        margin-top: 10px;
      }
    }
  }
}

.change-password-modal.ui.modal {
  max-width: 510px;

  label {
    display: inline-block;
    width: 200px;
  }

  .short-note {
    line-height: normal;
    margin-bottom: 10px;
    color: #ABA6A5;
  }

  div {
    margin-bottom: 10px;
  }

  input {
    border: none;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ABA6A5;
    padding: 3px 10px;
  }

  .container {
    height: 30px;
    margin: 0 0 10px 0;
  }

  .error.message {
    text-align: center;
    color: #FF0000;
  }

  .hidden.error.message {
    display: none;
  }
}
