
$reportTableTextColor: #ffffff;
$alternativeRowColor: #1f1f1f;
$headerBgColor: #1F1F1F;


.tt-table-container {
  color: $reportTableTextColor;
  $cellWidth: 100px;
  $maxCellWidth: 200px;

  td, th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    vertical-align: middle;
    border-right: 1px solid #000000;
    box-sizing: content-box;
    padding: 0 10px;
    width: $cellWidth;
    max-width: $maxCellWidth;
  }

  thead {
    font-weight: bold;

    tr {
      height: 45px;
      background-color: $headerBgColor;
    }
  }

  tbody {

    $levelOneBgColor: #434242;
    $levelTwoBgColor: #535353;
    $levelThreeBgColor: #6d6d6d;

    $rowBorderBottomColor: #000000;

    tr {
      height: 40px;
      border-bottom: 1px solid $rowBorderBottomColor;
    }

    tr.level-one {
      background: $levelOneBgColor;
    }

    tr.level-two {
      background: $levelTwoBgColor;
    }

    tr.level-three {
      background: $levelThreeBgColor;
    }
  }

  tr td:last-child, tr th:last-child {
    font-weight: bold;
    border: none;
  }

  .collapse-options-container {
    margin-bottom: 5px;

    .collapse-options {
      margin-right: 20px;

      input, label {
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }

  #reports-table_wrapper {
    max-width: 1000px;
  }
}
