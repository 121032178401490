@import "mixins";

.login-screen {
  $loginScreenBackgroundColor: #282828;
  $loginInputBackgroundColor: #1F1F1F;
  $loginHorizontalSeparatorColor: #555555;
  $loginFormSubmitBackgroundColor: #1F1F1F;

  $loginInputTextColor: #ffffff;
  $loginFormSubmitEnableTextColor: #ffffff;
  $loginFormSubmitDisabledTextColor: #555555;
  $loginMessageHeadColor: #FF2521;
  $loginMessageBodyColor: #FF2521;

  // found by trial and error
  $loginFormWidth: 555px;
  $appIconContainerWidth: 555px;
  $loginInputsWidth: 414px;

  $loginInputHeight: 50px;
  $separatorHeight: 2px;
  $loginMessageBoxHeight: 70px;
  $loginButtonHeight: 50px;

  background-color: $loginScreenBackgroundColor;
  position: relative;

  .login-container {
    // https://css-tricks.com/centering-css-complete-guide/
    // aligning a block element vertically and horizontally
    // inside another block element.
    // we intend to keep visual part of login screen in the centre of screen
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .login-screen-app-icon {
      $appIconContainerMarginBottom: 70px;

      width: $appIconContainerWidth;
      margin-bottom: $appIconContainerMarginBottom;

      img {
        display: block;
        margin: auto; // place image at centre of containing div
      }

    }

    .login-form-container {
      width: $loginFormWidth;


      .login-input-fields-container {
        width: $loginInputsWidth;
        background-color: $loginInputBackgroundColor;

        margin: auto;

        input:focus {
          outline: none;
        }

        .login-input {
          $loginInputPaddingLeft: 20px;
          $loginInputFontSize: 18px;

          background-color: $loginInputBackgroundColor;
          border: 0;
          color: $loginInputTextColor;
          // needed because some browsers add extra padding
          // and height was not set as specified
          padding: 0;
          // space between left border of div containing inputs and inputs
          padding-left: $loginInputPaddingLeft;
          height: $loginInputHeight;
          width: $loginInputsWidth;
          font-size: $loginInputFontSize;
        }
        :disabled {
          color: $loginFormSubmitDisabledTextColor;
        }

        // If we select the value for input field from browser suggestion
        // chrome will put some weired style with yellow background.
        // This is to change the style as per our design
        // http://stackoverflow.com/a/14205976
        :-webkit-autofill {
          -webkit-text-fill-color: white  !important;
          -webkit-box-shadow: inset 0 0 0 1000px $loginInputBackgroundColor !important;
        }

        ::-webkit-input-placeholder {
          $loginInputPlaceholderTextColor: #555555;

          color: $loginInputPlaceholderTextColor;
        }

        .input-separator {
          $separatorPaddingLeft: 35px;

          height: $separatorHeight;
          margin: 0;
          margin-left: $separatorPaddingLeft;
          width: $loginInputsWidth - $separatorPaddingLeft;
          background-color: $loginHorizontalSeparatorColor;
          border: none;
        }

      }

      .login-error-message{
        $loginMessageFontSize: 18px;

        height: $loginMessageBoxHeight;
        width: $loginFormWidth;
        font-size: $loginMessageFontSize;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          color: $loginMessageBodyColor;
          text-align: center;
        }
      }

      .login-button {
        $loginFormSubmitButtonMarginTop: 50px;
        $loginFormSubmitTextSize: 20px;

        border: none;
        display: block;
        margin: auto;
        height: $loginButtonHeight;
        width: $loginInputsWidth;
        color: $loginFormSubmitEnableTextColor;
        font-size: $loginFormSubmitTextSize;
        text-align: center;
        background-color: $loginFormSubmitBackgroundColor;

        // https://www.pexels.com/blog/css-only-loaders/
        .login-button-spinner {
          $spinnerColor: #555555;
          $spinnerSize: 28px;

          display: none;
          margin: auto;
          @include spinner-animation($spinnerSize, $spinnerColor);
        }
      }

      :disabled {
        color: $loginFormSubmitDisabledTextColor;
      }

      .sign-up-link {
        $messageDivWidth: $loginInputsWidth;
        $messageColor: grey;
        width: $messageDivWidth;
        margin: 20px auto;
        color: $messageColor;
        font-size: 18px;
        text-align: center;
      }
    }

  }

}

.ui.modal.email-not-verified {
  .content {
    text-align: center;

    strong {
      font-weight: bold;
    }
  }
}

.forgot-password-link {
  color: grey;
  text-align: center;
  margin: 15px;
  cursor: pointer;
}


.forgot-password-modal.ui.modal {
  max-width: 340px;

  .content {
    padding-bottom: 0;
    text-align: center;

    div {
      margin-bottom: 15px;
    }

    div:last-child {
      margin-bottom: 5px;
    }
  }

  input {
    border: 1px solid #343434;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ABA6A5;
    padding: 3px 10px;
    line-height: 30px;
    width: 270px;
  }

  input.error {
    border: 1px solid red;
  }

  .error-message {
    height: 30px;
    color: red;
    text-align: center;
  }

  .actions {
    padding: 5px 15px;
    height: 60px;

    .button {
      width: 100px;
      margin: 0 20px;
    }

    .send {
      float: left;
    }

    .cancel {
      float: right;
    }
  }
}
