.timeline-time-picker {
  margin-left: 30px;
}

.ui.time-picker-container:before {
  display: none;
}

.ui.popup.time-picker-container {
  $pickerBGColor: #1F1F1F;
  $pikerWidth: 250px;

  width: $pikerWidth;
  margin: 0;
  background: $pickerBGColor;
  border: none;
  border-radius: 0;


  .hours-picker, .minutes-picker {
    $columnWidth: 80px;

    display: inline-block;
    width: $columnWidth;

    .hour-input, .minute-input, .decrement-button, .increment-button {
      display: block;
      border: none;
    }

    .hour-input, .minute-input {
      $inputWidth: 40px;

      width: $inputWidth;
      text-align: center;
    }

    .ui.button.decrement-button, .ui.button.increment-button {
      $buttonWidth: 40px;
      $buttonHeight: 20px;

      width: $buttonWidth;
      height: $buttonHeight;
      padding: 0;

      i {
        margin: 0;
        font-weight: bold;
        width: auto;
      }
    }

    .ui.button.decrement-button {
      margin-top: 10px;
    }
    .ui.button.increment-button {

      margin-bottom: 10px;
    }
  }
  .am-pm-picker {
    position: absolute;
    top: 40px;
    left: 180px;
  }
}
