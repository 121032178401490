.report-dropdown, .modal-project-name, .users-in-project-list-container, .paging-dropdown {
  $dropdownBackgroundColor: #ABA6A5;
  $dropdownTextColor: #000000;
  $selectedItemTextColor: #FFFFFF;
  $selectedItemBackgroundColor: #5897FA;
  $dropDownWidth: 345px;
  $dropDownHeight: 28px;
  $dropdownPadding: 0 20px 0 10px;
  $multiDropdownSelectedColor: #8b8787;

  display: inline-block;

  .ui.selection.dropdown {
    width: $dropDownWidth;
    min-height: $dropDownHeight;
    background-color: $dropdownBackgroundColor;
    padding: $dropdownPadding;
    border: none;
    vertical-align: middle;
    color: $dropdownTextColor;

    .text {
      line-height: $dropDownHeight;
      margin: 0;
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
    }

    .dropdown.icon {
      padding: 6px 5px;
    }

    input.search {
      height: $dropDownHeight;
      margin: 0;
    }
    div.default.text {
      color: $dropdownTextColor;
    }

    .menu {
      background-color: $dropdownBackgroundColor;
      border: none;
      width: calc(100%);
      min-width: calc(100%);
      margin: 0;

      .item {
        height: $dropDownHeight;
        line-height: $dropDownHeight;
        border: none;
        padding: 0 0 0 10px !important;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .selected.item {
        // there is bug with dropdown navigation css
        // it does not go off on mouse navigation
        // so I'm looking for a fix, till then this is a hack

        background-color: transparent;
      }

      .active.item {
        font-weight: normal;
      }

      .item:hover {
        background-color: $selectedItemBackgroundColor;
        color: $selectedItemTextColor;
      }

      .selected.item:hover {
        background-color: $selectedItemBackgroundColor;
      }

      .active.item:hover{
        background-color: $selectedItemBackgroundColor;
      }
    }
  }

  .ui.selection.dropdown.report-for-dropdown, .ui.selection.dropdown.timeline-projects-dropdown {
    height: $dropDownHeight;

    input.search {
      padding: $dropdownPadding;
    }
  }

  .ui.multiple.search.dropdown > input.search {
    width: 10px; // default width
    text-align: left;
  }

  .ui.multiple.dropdown > .label {
    background-color: $multiDropdownSelectedColor;
  }
}

$timelineDropdownWidth: 255px;

.modal-project-name {
  .ui.selection.dropdown {
    width: $timelineDropdownWidth;
  }
}


.users-in-project-list-container {
  $userListBorderColor: #817D7C;
  $dropDownWidth: 200px;
  $dropdownHeight: 32px;
  $dropdownBackgroundColor: #282828;
  $dropdownTextColor: #FFFFFF;
  $activeElementColor: #1F1F1F;
  $placeholderColor: #A7A7A2;


  .ui.search.selection.dropdown {
    width: $dropDownWidth;
    height: $dropdownHeight;
    min-width: $dropDownWidth;
    background-color: $dropdownBackgroundColor;
    color: $dropdownTextColor;
    border: 1px solid $userListBorderColor;
    vertical-align: top;

    .menu {
      background-color: $dropdownBackgroundColor;
      border: 1px solid $userListBorderColor;
      width: calc(100% + 2px);
      min-width: calc(100% + 2px);
      left: -1px;

      .item, .message {
        color: $dropdownTextColor;
      }

      .active.item {
        background-color: $activeElementColor;
      }
    }
    div.default.text {
      color: $placeholderColor;
      line-height: $dropdownHeight;
    }
    input {
      padding: 0 10px;
    }
  }
  .ui.selection.visible.dropdown > .text:not(.default){
    color: $dropdownTextColor;
  }
}
